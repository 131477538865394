import React from 'react';
import homeStyles from './home.module.scss';
import Tilt from 'react-parallax-tilt';
import { CubicBezierCurve } from 'three';
import { Link } from '@reach/router';

const Home = () => {
	return (
		<div className={homeStyles.container}>
			<Tilt
				tiltMaxAngleX={8}
				tiltMaxAngleY={8}
				transitionEasing={CubicBezierCurve}
				trackOnWindow={true}
				gyroscope={true}
				className={homeStyles.tilt}
			>
				<div className={homeStyles.profile} />
			</Tilt>
			<h1>
				JAMES <br /> <span className={homeStyles.subtitle}>ASHFORD</span>
				<p>Front-End Developer</p>
				<Link to="/projects">GO TO PROJECTS</Link>
			</h1>
		</div>
	);
};

export default Home;
